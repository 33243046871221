<template>
    <div class="container">
        <!-- 免费领取 思派购药福利 -->
        <img
            class="bg"
            src="./banner@2x.png"
            alt="">
        <img
            class="fuli"
            src="./icon-fuli@2x.png"
            alt="">
        <img
            class="pie"
            src="./pie@2x.png"
            alt="">
        <img
            class="title"
            src="./title@2x.png"
            alt="">
        <div class="content">
            <img
                class="qrcode"
                src="./qrcode_default.jpg"
                alt="">
            <div class="focus">
                <div class="scan">
                    <img
                        src="./scan@2x.png"
                        class="scan_img"
                        alt="">
                </div>
                <span class="scan_tip">扫码关注【思派云药房公众号】</span>
            </div>
            <span class="service_title">您将免费获取以下服务：</span>
            <div class="service_tip_container">
                <span
                    class="service_tip">参与<span class="pink">临床招募项目</span></span>
                <span
                    class="service_tip"><span class="pink">慈善援助药品</span>服务</span>
                <span
                    class="service_tip">肿瘤专家<span class="pink">在线答疑</span></span>
                <span
                    class="service_tip">执业<span class="pink">药师用药指导</span></span>
            </div>
        </div>
        <!-- <div class="bottom_up">
            <span class="bottom_up_tip">长按识别，即刻关注，获取服务</span>
        </div> -->
        <div class="bottom">
            <span class="bottom_tip">如无法识别，请截图保存，至微信“扫一扫”</span>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    .bg {
        width: 375px;
    }
    .fuli {
        position: absolute;
        top: 25px;
        right: 4px;
        width: 116px;
        height: 114px;
        z-index: 100;
    }
    .pie {
        position: absolute;
        top: 82px;
        left: 198px;
        width: 177px;
        height: 99px;
    }
    .title {
        position: absolute;
        top: 38px;
        left: 16px;
        width: 241px;
        height: 110px;
    }
    .content {
        position: absolute;
        top: 164px;
        left: 19px;
        width: 339px;
        height: 393px;
        background: #fff9f5;
        box-shadow: 0 2px 16px 0 rgba(255, 90, 1, 0.37);
        border-radius: 12px;
        .qrcode {
            margin-top: 17px;
            margin-left: 107px;
            width: 123px;
            height: 123px;
            border: 4px solid #fe9e3c;
        }
        .focus {
            position: relative;
            margin-top: 15px;
            margin-bottom: 15px;
            margin-left: 13px;
            width: 312px;
            height: 37px;
            background: linear-gradient(90deg, #ff9e6f 0%, #ff3547 100%);
            border-radius: 19px;
            .scan {
                display: inline-block;
                position: relative;
                width: 37px;
                height: 37px;
                background: linear-gradient(90deg, #ff9e6f 0%, #ff3547 100%);
                border-radius: 27px;
                .scan_img {
                    position: absolute;
                    left: 9px;
                    top: 9px;
                    width: 19px;
                    height: 19px;
                }
            }
            .scan_tip {
                position: absolute;
                top: 6px;
                margin-left: 10px;
                width: 252px;
                height: 25px;
                font-size: 16px;
                color: #fff;
                line-height: 22px;
            }
        }
        .service_title {
            position: relative;
            margin-left: 79px;
            margin-bottom: 15px;
            width: 198px;
            height: 25px;
            font-size: 18px;
            color: #ff5b02;
            line-height: 22px;
            &::after {
                position: absolute;
                content: '';
                display: inline-block;
                bottom: 0;
                left: -2px;
                width: 192px;
                height: 6px;
                background: rgba(255, 173, 67, 0.43);
                border-radius: 3px;
            }
        }
        .service_tip_container {
            margin-top: 15px;
            text-align: center;
            .service_tip {
                margin-left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 132px;
                height: 22px;
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
                line-height: 22px;
                .pink {
                    color: #ff3e4b;
                }
                &::after {
                    position: absolute;
                    left: -22px;
                    top: 6px;
                    content: '';
                    display: inline-block;
                    width: 12px;
                    height: 11px;
                    // background: linear-gradient(239deg, #ffb86f 0%, #ff9e6f 15%, #ff3547 100%);
                    background-image: url('./icon_heart@2x.png');
                    background-size: 12px 11px;
                }
            }
        }
    }
    .bottom_up {
        position: fixed;
        bottom: 55px;
        width: 100%;
        height: 25px;
        text-align: center;
        .bottom_up_tip {
            position: relative;
            width: 252px;
            height: 25px;
            font-size: 18px;
            color: #ff5c02;
            line-height: 25px;
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                left: -30px;
                top: 0;
                width: 27px;
                height: 19px;
                background-image: url('./left_line@2x.png');
                background-size: 27px 19px;
            }
            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                right: -30px;
                top: 0;
                width: 27px;
                height: 19px;
                background-image: url('./right_line@2x.png');
                background-size: 27px 19px;
            }
        }
    }
    .bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 33px;
        text-align: center;
        background: rgba(255, 127, 23, 0.73);
        .bottom_tip {
            width: 270px;
            // height: 20px;
            line-height: 33px;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
        }
    }
}
</style>
